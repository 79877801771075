/**
 * Font-face
 * =====================================================================
 */

use('../plugins/file-ext.js')

font-face(family, path, weight=400, style=normal, data-uri=true)
    format = fileExt(path)
    @font-face
        font-family: family
        src: (data-uri ? inline-url(path) : url(path)) format(format)
        font-weight: weight
        font-style: style
